import { graphql }                    from 'gatsby';
import { IPage, IPageProps }          from '../api/com/ewing/social/interface';
import { ImageModule, SubmenuModule } from '../api/com/ewing/social/component';
import { ConsoleManager }             from '../api/com/ewing/social/manager';
import { SiteModule }                 from '../api/com/ewing/social/module/api/site.module';
import { PageObserver }               from '../api/com/ewing/social/observer';
import { Util }                       from '../api/com/ewing/social/tool';
import { BasePage }                   from '../components/atom/BasePage';

/**
 * @class Standard
 * @extends BasePage
 * @author Isaac Ewing
 * @version 1.0.0 04/23/21 02:55 pm
 */
export class Standard extends BasePage {
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 05/04/21 05:07 pm
      */
     protected static readonly CONSOLE_PREFIX: string   = `${ process.env.REACT_APP_CONSOLE_PREFIX_COMPONENT } STPG ${ process.env.REACT_APP_CONSOLE_SUFFIX_COMPONENT }`;
     /**
      *
      * @type {boolean}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 06/05/21 05:07 pm
      */
     protected static readonly CONSOLE_ENABLED: boolean = true;
     /**
      *
      * @type {string}
      * @readonly
      * @static
      * @protected
      * @author Isaac Ewing
      * @version 1.0.0 04/23/21 02:55 pm
      */
     protected static COMPONENT_CLASS: string           = 'page-home';

     /**
      *
      * @param {IPage} page
      * @return {string}
      * @static
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 05/29/21 03:25 pm
      */
     protected static buildComponentClass = ( page: IPage ): string => {
          const value: string = Util.cleanPath( page.text, false );

          switch( value ) {
               case 'social':
                    return 'page-platform';
               default:
                    return `page-${ value }`;
          }
     };

     /**
      *
      * @param props {any}
      * @return {void}
      * @constructor
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 04/23/21 02:55 pm
      */
     public constructor( props: IPageProps ) {
          super( props );

          if( props?.data ) {
               if( Standard.CONSOLE_ENABLED ) {
                    ConsoleManager.Log( Standard.CONSOLE_PREFIX ?? null, 'STANDARD PAGE', 'ABOUT to build site module...', { original: props.data } );
               }

               this.module = SiteModule.Build( props.data );
          }
          if( this.module ) {
               if( Standard.CONSOLE_ENABLED ) {
                    ConsoleManager.Info( Standard.CONSOLE_PREFIX ?? null, 'STANDARD PAGE', 'CALLING register section modules...',
                                         { module: this.module, page: this.module?.page, path: this.module?.page?.path } );
               }

               this.module.store();
               this.submenu             = SubmenuModule.BuildForDetect( this.module.page.path );
               this.sections            = this.registerSectionModules( this.module.sections, this.module );
               BasePage.COMPONENT_CLASS = Standard.COMPONENT_CLASS = Standard.buildComponentClass( this.module.page );
               ImageModule.SetStatic( +this.module.domain.id, props.pageContext.images );

               if( Standard.CONSOLE_ENABLED ) {
                    ConsoleManager.Log( Standard.CONSOLE_PREFIX ?? null, 'STANDARD PAGE', 'JUST finished setting sections property...',
                                        { sections: this.sections, submenu: !!this.submenu } );
               }
          }

          this.state = {
               path     : props?.path ?? null,
               hash     : this.registerHash(),
               loader   : this.registerHash(),
               query    : null,
               data     : null,
               submenu  : !!this.submenu,
               className: new Set<string>( [ 'root-main', Standard.COMPONENT_CLASS ] ),
               children : new Set<JSX.Element>(),
          };

          PageObserver.subscribe( Standard.COMPONENT_CLASS, this.onPageObserver );

          if( Standard.CONSOLE_ENABLED ) {
               ConsoleManager.Log( Standard.CONSOLE_PREFIX ?? null, 'STANDARD PAGE', 'just finished calling SUBSCRIBE on the page observer...' );
          }
     }

     /**
      *
      * @return {void}
      * @public
      * @author Isaac Ewing
      * @version 1.0.0 04/23/21 02:55 pm
      */
     public componentWillUnmount(): void {
          super.componentWillUnmount();
          PageObserver.unsubscribe( Standard.COMPONENT_CLASS );

          if( Standard.CONSOLE_ENABLED ) {
               ConsoleManager.Log( Standard.CONSOLE_PREFIX ?? null, 'STANDARD PAGE', 'just finished calling UNMOUNT...' );
          }
     }
}

export const query: void = graphql`
     query page( $domain: Int, $domainName: String = null, $page: Int, $pageName: String = null, $contact: Boolean = true, $invite: Boolean = false, $platforms: Boolean = true ) {
          socialAPI {
               getSitePage(
                    where: {
                         domain: $domain,
                         domainName: $domainName,
                         page: $page,
                         pageName: $pageName
                    }
                    options: {
                         includes: {
                              contact: $contact,
                              invite: $invite
                              platforms: $platforms
                         }
                    }
               ) {
                    ...ewingSocialPageDetailsFragment
               }
          }
     }

     fragment ewingSocialPageDetailsFragment on EwingSocialAPI_Site {
          domain {
               id
               path
               text
          }
          page {
               id
               path
               text
          }
          pages {
               page {
                    id
                    path
                    text
               }
               sections {
                    id
                    type
                    style
                    order
                    curve
                    layout {
                         id
                         title {
                              id
                              text
                         }
                         description {
                              id
                              text
                         }
                    }
                    data {
                         id
                         text
                    }
                    title {
                         id
                         text
                    }
                    subtitle {
                         id
                         text
                    }
                    description {
                         id
                         text
                    }
                    menu {
                         id
                         submenu
                         discord
                         facebook
                         instagram
                         onlyfans
                         patreon
                         tiktok
                         twitch
                         twitter
                         youtube
                         columns
                         links {
                              id
                              path
                              text
                              icon
                         }
                    }
                    gallery {
                         id
                         isGallery
                         valign
                         halign
                         images {
                              id
                              image {
                                   id
                                   domain {
                                        id
                                   }
                                   source
                                   path
                                   imageFile {
                                        publicURL
                                        childImageSharp {
                                             gatsbyImageData(
                                                  formats: [AUTO, AVIF, WEBP, PNG]
                                                  placeholder: BLURRED
                                                  layout: CONSTRAINED
                                             )
                                        }
                                   }
                                   alt
                                   favor
                                   valign
                                   halign
                                   logo
                                   rounded
                                   greyed
                                   shadow
                                   webp
                                   png
                                   jpg
                                   jpeg
                                   gif
                                   svg
                                   jxr
                              }
                              title {
                                   id
                                   text
                              }
                              link {
                                   id
                                   path
                                   text
                                   icon
                              }
                         }
                    }
                    image {
                         id
                         domain {
                              id
                         }
                         source
                         path
                         imageFile {
                              publicURL
                              childImageSharp {
                                   gatsbyImageData(
                                        formats: [AUTO, AVIF, WEBP, PNG]
                                        placeholder: BLURRED
                                        layout: CONSTRAINED
                                   )
                              }
                         }
                         alt
                         favor
                         valign
                         halign
                         logo
                         rounded
                         greyed
                         shadow
                         webp
                         png
                         jpg
                         jpeg
                         gif
                         svg
                         jxr
                    }
                    button {
                         id
                         path
                         text
                         icon
                    }
               }
          }
          setup {
               id
               login
               intro
          }
          invite {
               id
               active
               nonce
               used
               code
          }
          platforms
          sections {
               id
               type
               style
               order
               curve
               layout {
                    id
                    title {
                         id
                         text
                    }
                    description {
                         id
                         text
                    }
               }
               data {
                    id
                    text
               }
               title {
                    id
                    text
               }
               subtitle {
                    id
                    text
               }
               description {
                    id
                    text
               }
               menu {
                    id
                    submenu
                    discord
                    facebook
                    instagram
                    onlyfans
                    patreon
                    tiktok
                    twitch
                    twitter
                    youtube
                    columns
                    links {
                         id
                         path
                         text
                         icon
                    }
               }
               gallery {
                    id
                    isGallery
                    valign
                    halign
                    images {
                         id
                         image {
                              id
                              domain {
                                   id
                              }
                              source
                              path
                              imageFile {
                                   publicURL
                                   childImageSharp {
                                        gatsbyImageData(
                                             formats: [AUTO, AVIF, WEBP, PNG]
                                             placeholder: BLURRED
                                             layout: CONSTRAINED
                                        )
                                   }
                              }
                              alt
                              favor
                              valign
                              halign
                              logo
                              rounded
                              greyed
                              shadow
                              webp
                              png
                              jpg
                              jpeg
                              gif
                              svg
                              jxr
                         }
                         title {
                              id
                              text
                         }
                         link {
                              id
                              path
                              text
                              icon
                         }
                    }
               }
               image {
                    id
                    domain {
                         id
                    }
                    source
                    path
                    imageFile {
                         publicURL
                         childImageSharp {
                              gatsbyImageData(
                                   formats: [AUTO, AVIF, WEBP, PNG]
                                   placeholder: BLURRED
                                   layout: CONSTRAINED
                              )
                         }
                    }
                    alt
                    favor
                    valign
                    halign
                    logo
                    rounded
                    greyed
                    shadow
                    webp
                    png
                    jpg
                    jpeg
                    gif
                    svg
                    jxr
               }
               button {
                    id
                    path
                    text
                    icon
               }
          }
     }
`;

export default Standard;